if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || 
							Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

import { contactSubmit } from './contact.js';
import { getMapsHelper } from './util/mapsHelper';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.querySelector('#map_canvas')) {
	const mapHelper = getMapsHelper();

	mapHelper.ready().then(() => {
		const theMap = mapHelper.createMap({
			element: document.querySelector('#map_canvas'),
			locationElementSelector: '#map_canvas'
		});

		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(13);
		});
	});
}

if(document.querySelector('#home_directions')) {
	const form = document.querySelector('#home_directions');
	form.addEventListener('submit', e => {
		e.preventDefault();
		e.stopImmediatePropagation();

		const encodedAddresses = {
			start: encodeURI(form.querySelector('#start').value),
			end: encodeURI(form.querySelector('#end').value),
		};

		window.open(`https://www.google.com/maps/dir/${encodedAddresses.start}/${encodedAddresses.end}`);
	});
}